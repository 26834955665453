import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { AiOutlineCheck } from 'react-icons/ai';

import playIcon from '../assets/playIcon.png';
import sortIcon from '../assets/sortIcon.png';

import moment from 'moment';
import momentTimezone from 'moment-timezone';

import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Table,
  Card,
  CardBody,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip
} from 'reactstrap';

import {
  getSharedAnalytics,
} from "../services/API";

import "../theme.scss";

const AnalyticsShared = () => {

    const initialValues = {
        search: '',
        analyticsDate: '',
        analyticsDateTo: '',
        selectedCampaign: '',
      };

    const { campaign_id } = useParams();
    const { share_key } = useParams();
    const [videoSrc, setVideoSrc] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [dropdownOpen2, setDropdownOpen2] = useState(false);
    const [dropdownOpen3, setDropdownOpen3] = useState(false);
    const toggle = () => setDropdownOpen(prevState => !prevState);
    const toggle2 = () => setDropdownOpen2(prevState => !prevState);
    const toggle3 = () => setDropdownOpen3(prevState => !prevState);
    const [form, setValues] = useState(initialValues);
    const [peopleAdded, setPeopleAdded] = useState(0);
    const [pageVisits, setPageVisits] = useState(0);
    const [ctaClicks, setCtaClicks] = useState(0);
    const [uniqueViewers, setUniqueViewers] = useState(0);
    const [conversions, setConversions] = useState(0);
    const [watchRate, setWatchRate] = useState(0);
    const [usedCredits, setUsedCredits] = useState(0);
    const [people, setPeople] = useState([]);
    const [modal, setModal] = useState(false);
    const [message, setMessage] = useState('');
    const [init, setInit] = useState(false);
    const [messageModal, setMessageModal] = useState(false);
    const [timeout, setTimeoutVar] = useState(false);
    const [campaign, setCampaign] = useState({});
    const [campaignNamesIds, setCampaignNamesIds] = useState([]);
    const [ showCampaigns, setShowCampaigns ] = useState(false);
    const [ filteredCampaigns, setFilteredCampaigns ] = useState([]);
    const [ tags, setTags ] = useState([]);
    const [ tag, setTag ] = useState('Tags');
    const [ sampaignJumpName, setCampaignJumpName ] = useState('');
    const [ dateRange, setDateRange ] = useState({name: 'Date Range'});
    const toggleModal = () => setModal(!modal);
    const toggleMessageModal = () => setMessageModal(!messageModal);
    const [ filterEmailTable, setfilterEmailTable ] = useState(null);
    const [ filterWatchRateTable, setfilterWatchRateTable ] = useState(null);
    const [ filterIdTable, setfilterIdTable ] = useState('DESC');
    const [ totalEmails, settotalEmails ] = useState(0);
    const [ stepInSequence, setstepInSequence ] = useState([]);
    const [ stepInSequenceSelected, setstepInSequenceSelected ] = useState('0');
    const [ filterSentEmail, setfilterSentEmail ] = useState(false);
    const [ filterOpenedEmail, setfilterOpenedEmail ] = useState(false);
    const [ filterClickedEmail, setfilterClickedEmail ] = useState(false);
    const [ filterScheduledEmail, setfilterScheduledEmail ] = useState(false);
    const [ filterUnsubscribedEmail, setfilterUnsubscribedEmail ] = useState(false);
    const [ filterBouncedEmail, setfilterBouncedEmail ] = useState(false);
    const [ filterEmailsNameEmail, setfilterEmailsNameEmail ] = useState('');
    const [showEmailStats, setshowEmailStats] = useState(true);
    const [ increasePeople, setIncreasePeople ] = useState(0);
    const [ uniqueViewer, setUniqueViewer ] = useState(0);
    const [ increaseCta, setIncreaseCta ] = useState(0);
    const [ increaseConversion, setIncreaseConversion ] = useState(0);
    const [ increaseWatchRate, setIncreaseWatchRate ] = useState(0);
    const [ scheduled, setscheduled ] = useState(0);
    const [ unsubscribed, setunsubscribed ] = useState(0);
    const [ bounced, setbounced ] = useState(0);
    const [ peoples, setPeoples ] = useState([]);
    const [ sentEmails, setsentEmails ] = useState(0);
    const [ openedEmails, setopenedEmails ] = useState(0);
    const [ clickedEmails, setclickedEmails ] = useState(0);
    const [serverTimezone, setserverTimezone] = useState(null);
    const [ loading, setloading ] = useState(false);

    const [pagesGallery, setPagesGallery] = useState({
      currentPage: null,
      links: []
    });

    useEffect(() => {
      setloading(true);
    }, [stepInSequenceSelected]);

    useEffect(() => {
      if (init)
        analyticsUrlDeb(1, null, null, null, null);
    }, [
      filterSentEmail,
      filterOpenedEmail,
      filterClickedEmail,
      filterScheduledEmail,
      filterUnsubscribedEmail,
      filterBouncedEmail,
      filterEmailsNameEmail,
      stepInSequenceSelected,
      filterEmailTable,
      filterWatchRateTable,
      filterIdTable
    ]);

    function openVideo(item) {
      setVideoSrc(item.video.video_url);
      setModal(true);
    }

    useEffect(() => {

      setCampaign({});

      if (!init) {
        analyticsUrlDeb(1, null, null, null, null);

        setInit(true);

      } else {

        let date = null;
        let toDate = null;

        if (isValidDate(form.analyticsDate))
          date = form.analyticsDate;

        if (isValidDate(form.analyticsDateTo))
          toDate = form.analyticsDateTo;

        if (tag && tag !== 'Tags')
          analyticsUrlDeb(1, date, form.search, tag, toDate);
        else
          analyticsUrlDeb(1, date, form.search, null, toDate);

      }

    }, [form.analyticsDate, form.search, campaign_id, form.analyticsDateTo]);

    function setDateRangeInputs(days) {

      let now = new Date();
      let month = (now.getMonth() + 1);
      let day = now.getDate();
      if (month < 10)
          month = "0" + month;
      if (day < 10)
          day = "0" + day;

      let today = now.getFullYear() + '-' + month + '-' + day;

      let toDate = new Date();
      toDate.setDate(toDate.getDate() - days);

      let monthTo = (toDate.getMonth() + 1);
      let dayTo = toDate.getDate();
      if (monthTo < 10)
          monthTo = "0" + monthTo;
      if (dayTo < 10)
          dayTo = "0" + dayTo;

      let dateTo = toDate.getFullYear() + '-' + monthTo + '-' + dayTo;

      setValues({
          ...form,
          analyticsDate: dateTo,
          analyticsDateTo: today,
      });

    }

    function handleChange (e) {
      const { id, value } = e.target;

      if (id==='search') {
        setShowCampaigns(true);
        fiterSelectCampaigns(value);
        setCampaignJumpName(value);
        return;
      }

      setValues({
          ...form,
          [id]: value,
      });
    };

    function fiterSelectCampaigns(value) {

      let cmps = [...campaignNamesIds];

      if (tag === 'Tags') {
        cmps = cmps.filter((val) => {
          return val.name.indexOf(value) > -1;
        });
      } else {
        cmps = cmps.filter((val) => {
          return val.name.indexOf(value) > -1 && val.tag === tag;
        });
      }

      setFilteredCampaigns(cmps);
    }

    function isValidDate(dateString) {

      // First check for the pattern
      if(!/^\d{4}-\d{2}-\d{2}$/.test(dateString))
          return false;

      // Parse the date parts to integers
      var parts = dateString.split("-");
      var day = parseInt(parts[2], 10);
      var month = parseInt(parts[1], 10);
      var year = parseInt(parts[0], 10);

      var today = new Date();
      var now = today.getFullYear();

      // Check the ranges of month and year
      if(year < 2020 || year > now || month == 0 || month > 12)
          return false;

      var monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];

      // Adjust for leap years
      if(year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
          monthLength[1] = 29;

      // Check the range of the day
      return day > 0 && day <= monthLength[month - 1];
    };

    function analyticsUrlDeb(page, date = null, search = null, tag=null, toDate) {

      if (timeout)
        clearTimeout(timeout);

      let tmpTimeout;

      tmpTimeout = setTimeout(function() {
        doGetAnalytics(page, date, search, tag, toDate);
      }, 1000);

      setTimeoutVar(tmpTimeout);

    }

    function doGetAnalytics(page, date, search, tag = null, dateTo = null) {

      setloading(true);

      if (campaign_id) {

        let filters = {
          filter_sent_email: filterSentEmail,
          filter_opened_email: filterOpenedEmail,
          filter_clicked_email: filterClickedEmail,
          filter_scheduled_email: filterScheduledEmail,
          filter_unsubscribed_email: filterUnsubscribedEmail,
          filter_bounced_email: filterBouncedEmail,
          filter_emails_name_email: filterEmailsNameEmail,
          filter_email_table: filterEmailTable,
          filter_watch_rate_table: filterWatchRateTable,
          filter_id_table: filterIdTable,
        }

        //loading on
        getSharedAnalytics(campaign_id, page, date, search, tag, dateTo, share_key, stepInSequenceSelected, filters)
            .then((resp) => {
              setloading(false);
              //loading off

              if (resp.status) {
                setPeopleAdded(resp.count_people);

                setIncreasePeople(resp.increase_people_added);
                setUniqueViewer(resp.increase_unique_vievwer);
                setIncreaseCta(resp.increase_cta);
                setIncreaseWatchRate(resp.increase_watch_rate);
                setConversions(resp.conversions.conversions);
                setIncreaseConversion(resp.increase_conversions);
                setCtaClicks(resp.cta_clicks.clicks);
                setPageVisits(resp.page_visits);
                setUniqueViewers(resp.unique_vievwer);
                setWatchRate(resp.watch_rate);
                setUsedCredits(resp.used_credits.used_credits);
                resp.set_people && setPeoples(resp.set_people.data);
                //resp.people && setPeople(resp.people.data);
                setCampaign(resp.campaign);
                resp.schedules_data && setsentEmails(resp.schedules_data.sent_emails);
                resp.schedules_data && settotalEmails(resp.schedules_data.total);
                resp.schedules_data && setopenedEmails(resp.schedules_data.opened);
                resp.schedules_data && setclickedEmails(resp.schedules_data.clicked);
                resp.schedules_data && setunsubscribed(resp.schedules_data.unsubscribed);
                resp.schedules_data && setbounced(resp.schedules_data.bounced);

                if (!resp.schedules_data)
                  setshowEmailStats(false);

                resp.timezone && setserverTimezone(resp.timezone.timezone);

                let emailData = resp.schedules_data ? resp.schedules_data.email_data : null;

                setstepInSequence(emailData.email_data);

                setscheduled(resp.count_scheduled);

                let currentPg = null;
                let i;

                for (i in resp.set_people.links) {
                    if (resp.set_people.links[i].active)
                    currentPg = parseInt(resp.set_people.links[i].label);
                }

                setPagesGallery({
                    links: resp.set_people.links,
                    currentPage: currentPg
                });

              }

            })
            .catch((resp) => {
                //loading off
                setloading(false);
                console.log(resp);
            });
        }

    }

    function readMessage(message) {
      if (message)
        setMessage(message);
      else
        setMessage('');

      setMessageModal(true);
    }

    function printAnalytics() {

      window.print();

    }


    const getPerOpenedEmails = () => {
      let ret = 0;

      if (openedEmails && totalEmails) {
        ret = openedEmails / totalEmails*100;
      }

      return ret.toFixed(0);
    }

    const getPerClickedEmails = () => {
      let ret = 0;

      if (clickedEmails && totalEmails) {
        ret = clickedEmails / totalEmails*100;
      }

      return ret.toFixed(0);
    }

    const getPerUnsubscribedEmails = () => {
      let ret = 0;

      if (unsubscribed && totalEmails) {
        ret = unsubscribed / totalEmails*100;
      }

      return ret.toFixed(0);
    }

    const getPerBouncedEmails = () => {
      let ret = 0;

      if (bounced && totalEmails) {
        ret = bounced / totalEmails*100;
      }

      return ret.toFixed(0);
    }


    const getDate = (date) => {
      let user = momentTimezone.tz.guess();

      if (!serverTimezone)
        return '';

      let now = moment.utc();
      // get the zone offsets for this time, in minutes
      let servetTzOffset = moment.tz.zone(serverTimezone).utcOffset(now);
      let userTzOffset = moment.tz.zone(user).utcOffset(now);
      // calculate the difference in minutes
      let difference = servetTzOffset - userTzOffset
      return moment(date).add({minutes: difference}).fromNow();
    }

    const checkOpened = (sheduledSentEmails) => {

      let ret = false;
      if (!sheduledSentEmails || !Array.isArray(sheduledSentEmails))
        return false;
      sheduledSentEmails.map((item) => {
        if (item.opened)
          ret = true;
      });

      return ret;
    }

    const checkClicked = (sheduledSentEmails) => {

      let ret = false;
      if (!sheduledSentEmails || !Array.isArray(sheduledSentEmails))
        return false;
      sheduledSentEmails.map((item) => {
        if (item.clicked)
          ret = true;
      });

      return ret;
    }

    const checkBounced = (sheduledSentEmails) => {

      let ret = false;
      if (!sheduledSentEmails || !Array.isArray(sheduledSentEmails))
        return false;
      sheduledSentEmails.map((item) => {
        if (!item.publish_email_id)
          ret = true;
      });

      return ret;
    }

    const checkUnsubscribed = (sheduledSentEmails) => {

      let ret = false;
      if (!sheduledSentEmails || !Array.isArray(sheduledSentEmails))
        return false;
      sheduledSentEmails.map((item) => {
        if (item.unsubscribed)
          ret = true;
      });

      return ret;
    }

    return (
      <>
        { loading &&
          <div className="container-fluid page-content loader-wrapper">
            <div className="loader"></div>
        </div>}
        <div className="container-fluid page-content analytics">

            <div className="d-flex hide-print">
                <h1>Analytics: Overview</h1>
                <div className="d-flex align-items-center ml-auto">


                    <div>
                      &nbsp;
                        <Dropdown isOpen={dropdownOpen3} toggle={toggle3} className="font-nunito">
                        <DropdownToggle caret className="bg-transparent color-a-2">

                        {dateRange.name} <i className="fas fa-angle-down ml-2"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem onClick={(e) => {setDateRangeInputs(0)}}>Today</DropdownItem>
                          <DropdownItem onClick={(e) => {setDateRangeInputs(7)}}>Last 7 days</DropdownItem>
                          <DropdownItem onClick={(e) => {setDateRangeInputs(28)}}>Last 28 days</DropdownItem>
                          <DropdownItem onClick={(e) => {setDateRangeInputs(30)}}>Last 30 days</DropdownItem>
                          <DropdownItem onClick={(e) => {setDateRangeInputs(90)}}>Last 90 days</DropdownItem>
                          <DropdownItem onClick={(e) => {setDateRangeInputs(366)}}>Last 12 months</DropdownItem>
                        </DropdownMenu>
                        </Dropdown>
                    </div>
                    <div className="analytics-search">
                        <div className="search-box ml-2">
                            <div className="position-relative">
                                From Date: <input type="date" id="analyticsDate" value={form.analyticsDate} onChange={e => handleChange(e)} className="form-control rounded date" />
                            </div>
                        </div>
                    </div>
                    <div className="analytics-search">
                        <div className="search-box ml-2">
                            <div className="position-relative">
                                To Date: <input type="date" id="analyticsDateTo" value={form.analyticsDateTo} onChange={e => handleChange(e)} className="form-control rounded date" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <p className="small-regular font-nunito mb-5 hide-print">See how your campains are performing</p>
            <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center">
                    <h6 className="font-weight-bold mr-2 my-0">{campaign.name}</h6>
                    <p className="my-0 small-regular color-alpha-analytics">ID: {campaign_id}</p>
                </div>
                <div className="d-flex align-items-center icons-mod">
                    <div className="mr-3">
                      <svg onClick={() => {printAnalytics()}} className="pointer" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M4.75 1.77778C4.75 1.63752 4.86193 1.52381 5 1.52381H10H11C11.1381 1.52381 11.25 1.63752 11.25 1.77778V2.79365V4.8254H4.75V1.77778ZM3.25 4.8254V1.77778C3.25 0.795938 4.0335 0 5 0H10H11C11.9665 0 12.75 0.795938 12.75 1.77778V2.79365V4.8254H14.5H15C15.5523 4.8254 16 5.28022 16 5.84127V6.34921V11.4286V11.9365C16 12.4976 15.5523 12.9524 15 12.9524H14.5H13.5H12V14.4762V14.9841C12 15.5452 11.5523 16 11 16H5C4.44772 16 4 15.5452 4 14.9841V14.4762V12.9524H2.5H1.5H1C0.447715 12.9524 0 12.4976 0 11.9365V11.4286V6.34921V5.84127C0 5.28022 0.447715 4.8254 1 4.8254H1.5H3.25ZM10.5 11.4286V12.9524V14.4762H5.5V12.9524V11.4286V10.4127H10.5V11.4286ZM10.5 8.88889H12C12.4142 8.88889 12.75 9.23001 12.75 9.65079C12.75 10.0716 12.4142 10.4127 12 10.4127V11.4286H13H13.5H14.5V9.90476V7.87302V6.34921H13H3H1.5V7.87302V9.90476V11.4286H2.5H3H4V10.4127C3.58579 10.4127 3.25 10.0716 3.25 9.65079C3.25 9.23001 3.58579 8.88889 4 8.88889H5.5H10.5Z" fill="#171F46"/>
                      </svg>
                    </div>
                    <div>
                        <p className="anal-mod font-nunito">Credit Used: {usedCredits}</p>
                    </div>
                </div>
            </div>
            <div className="row mx-0 mt-3 p-0">
                <div className=".col-12 col pl-0">
                    <div className="card p-4">
                        <div className="d-flex justify-content-between">
                            <p className="font-nunito">People Added</p>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M20.083 15.1998L21.285 15.9208C21.4358 16.0111 21.5282 16.174 21.5282 16.3498C21.5282 16.5256 21.4358 16.6885 21.285 16.7788L12.515 22.0408C12.198 22.2312 11.8019 22.2312 11.485 22.0408L2.71498 16.7788C2.56413 16.6885 2.4718 16.5256 2.4718 16.3498C2.4718 16.174 2.56413 16.0111 2.71498 15.9208L3.91698 15.1998L12 20.0498L20.083 15.1998V15.1998ZM20.083 10.4998L21.285 11.2208C21.4358 11.3111 21.5282 11.474 21.5282 11.6498C21.5282 11.8256 21.4358 11.9885 21.285 12.0788L12 17.6498L2.71498 12.0788C2.56413 11.9885 2.4718 11.8256 2.4718 11.6498C2.4718 11.474 2.56413 11.3111 2.71498 11.2208L3.91698 10.4998L12 15.3498L20.083 10.4998ZM12.514 1.30883L21.285 6.57083C21.4358 6.66113 21.5282 6.82402 21.5282 6.99983C21.5282 7.17563 21.4358 7.33852 21.285 7.42882L12 12.9998L2.71498 7.42882C2.56414 7.33852 2.4718 7.17563 2.4718 6.99983C2.4718 6.82402 2.56414 6.66113 2.71498 6.57083L11.485 1.30883C11.8019 1.11841 12.198 1.11841 12.515 1.30883H12.514ZM12 3.33183L5.88696 6.99983L12 10.6678L18.113 6.99983L12 3.33183Z" fill="#5664D2"/>
                            </svg>
                        </div>
                        <h2 className="analytic mb-40">{campaign_id ? peopleAdded : '...'}</h2>
                        <div className="d-flex align-items-center mt-auto">
                        <div className={'badge badge-pill d-block font-weight-bold my-3 font-nunito py-1 px-3 analytic-badge ' + (increasePeople > 0 ? 'badge-success' : '') + (increasePeople === 0 ? 'badge-secondary' : '') + (increasePeople < 0 ? 'badge-danger' : '')}>{increasePeople}%</div>
                        <p className="my-0 ml-3 font-nunito">From previous period</p>
                        </div>
                    </div>
                </div>
                <div className=".col-12 col">
                  <div className="card p-4">
                        <div className="d-flex justify-content-between">
                            <p className="font-nunito">Page Visits</p>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M21 13.242V20H22V22H2V20H3V13.242C1.74968 12.4075 0.999171 11.0033 1 9.5C1 8.673 1.224 7.876 1.633 7.197L4.345 2.5C4.52363 2.19061 4.85374 2.00001 5.211 2H18.79C19.1473 2.00001 19.4774 2.19061 19.656 2.5L22.358 7.182C23.5973 9.24037 22.9992 11.9093 21 13.242ZM19 13.972C17.6063 14.1283 16.2195 13.6254 15.25 12.612C14.4017 13.4993 13.2275 14.0011 12 14.001C10.7728 14.0016 9.59863 13.5005 8.75 12.614C7.78034 13.627 6.39354 14.1296 5 13.973V20H19V13.973V13.972ZM5.789 4L3.356 8.213C2.86563 9.42459 3.39133 10.8094 4.56218 11.3904C5.73303 11.9714 7.15379 11.5524 7.822 10.429C8.157 9.592 9.342 9.592 9.678 10.429C10.0571 11.3792 10.9769 12.0025 12 12.0025C13.0231 12.0025 13.9429 11.3792 14.322 10.429C14.657 9.592 15.842 9.592 16.178 10.429C16.5318 11.2999 17.3442 11.8987 18.2808 11.9789C19.2174 12.0592 20.1198 11.6072 20.6166 10.8092C21.1133 10.0112 21.1204 9.00196 20.635 8.197L18.21 4H5.79H5.789Z" fill="#5664D2"/>
                            </svg>

                        </div>
                        <h2 className="analytic mb-40">{campaign_id ? pageVisits : '...'}</h2>
                        <div className="d-flex align-items-center mt-auto">
                        <div className={'badge badge-pill d-block font-weight-bold my-3 font-nunito py-1 px-3 analytic-badge ' + (increasePeople > 0 ? 'badge-success' : '') + (increasePeople === 0 ? 'badge-secondary' : '') + (increasePeople < 0 ? 'badge-danger' : '')}>{increasePeople}%</div>
                        <p className="my-0 ml-3 font-nunito">From previous period</p>
                        </div>
                    </div>
                </div>
                <div className=".col-12 col">
                  <div className="card p-4">
                        <div className="d-flex justify-content-between">
                            <p className="font-nunito">Plays</p>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M21 13.242V20H22V22H2V20H3V13.242C1.74968 12.4075 0.999171 11.0033 1 9.5C1 8.673 1.224 7.876 1.633 7.197L4.345 2.5C4.52363 2.19061 4.85374 2.00001 5.211 2H18.79C19.1473 2.00001 19.4774 2.19061 19.656 2.5L22.358 7.182C23.5973 9.24037 22.9992 11.9093 21 13.242ZM19 13.972C17.6063 14.1283 16.2195 13.6254 15.25 12.612C14.4017 13.4993 13.2275 14.0011 12 14.001C10.7728 14.0016 9.59863 13.5005 8.75 12.614C7.78034 13.627 6.39354 14.1296 5 13.973V20H19V13.973V13.972ZM5.789 4L3.356 8.213C2.86563 9.42459 3.39133 10.8094 4.56218 11.3904C5.73303 11.9714 7.15379 11.5524 7.822 10.429C8.157 9.592 9.342 9.592 9.678 10.429C10.0571 11.3792 10.9769 12.0025 12 12.0025C13.0231 12.0025 13.9429 11.3792 14.322 10.429C14.657 9.592 15.842 9.592 16.178 10.429C16.5318 11.2999 17.3442 11.8987 18.2808 11.9789C19.2174 12.0592 20.1198 11.6072 20.6166 10.8092C21.1133 10.0112 21.1204 9.00196 20.635 8.197L18.21 4H5.79H5.789Z" fill="#5664D2"/>
                            </svg>

                        </div>
                        <h2 className="analytic mb-40">{campaign_id ? pageVisits : '...'}</h2>
                        <div className="d-flex align-items-center mt-auto">
                        <div className={'badge badge-pill d-block font-weight-bold my-3 font-nunito py-1 px-3 analytic-badge ' + (increasePeople > 0 ? 'badge-success' : '') + (increasePeople === 0 ? 'badge-secondary' : '') + (increasePeople < 0 ? 'badge-danger' : '')}>{increasePeople}%</div>
                        <p className="my-0 ml-3 font-nunito">From previous period</p>
                        </div>
                    </div>
                </div>
                <div className=".col-12 col">
                  <div className="card p-4">
                        <div className="d-flex justify-content-between">
                            <p className="font-nunito">Watch Rate</p>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M21 13.242V20H22V22H2V20H3V13.242C1.74968 12.4075 0.999171 11.0033 1 9.5C1 8.673 1.224 7.876 1.633 7.197L4.345 2.5C4.52363 2.19061 4.85374 2.00001 5.211 2H18.79C19.1473 2.00001 19.4774 2.19061 19.656 2.5L22.358 7.182C23.5973 9.24037 22.9992 11.9093 21 13.242ZM19 13.972C17.6063 14.1283 16.2195 13.6254 15.25 12.612C14.4017 13.4993 13.2275 14.0011 12 14.001C10.7728 14.0016 9.59863 13.5005 8.75 12.614C7.78034 13.627 6.39354 14.1296 5 13.973V20H19V13.973V13.972ZM5.789 4L3.356 8.213C2.86563 9.42459 3.39133 10.8094 4.56218 11.3904C5.73303 11.9714 7.15379 11.5524 7.822 10.429C8.157 9.592 9.342 9.592 9.678 10.429C10.0571 11.3792 10.9769 12.0025 12 12.0025C13.0231 12.0025 13.9429 11.3792 14.322 10.429C14.657 9.592 15.842 9.592 16.178 10.429C16.5318 11.2999 17.3442 11.8987 18.2808 11.9789C19.2174 12.0592 20.1198 11.6072 20.6166 10.8092C21.1133 10.0112 21.1204 9.00196 20.635 8.197L18.21 4H5.79H5.789Z" fill="#5664D2"/>
                            </svg>

                        </div>
                        <h2 className="analytic mb-40">{campaign_id ? (watchRate ? parseInt(watchRate) + '%' : '0%') : '...'}</h2>
                        <div className="d-flex align-items-center mt-auto">
                        <div className={'badge badge-pill d-block font-weight-bold my-3 font-nunito py-1 px-3 analytic-badge ' + (increaseWatchRate > 0 ? 'badge-success' : '') + (increaseWatchRate === 0 ? 'badge-secondary' : '') + (increaseWatchRate < 0 ? 'badge-danger' : '')}>{increaseWatchRate}%</div>
                        <p className="my-0 ml-3 font-nunito">From previous period</p>
                        </div>
                    </div>
                </div>
                <div className=".col-12 col pr-0">
                    <div className="card p-4">
                        <div className="d-flex justify-content-between">
                            <p className="font-nunito">Conversions</p>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M7 5V2C7 1.44772 7.44772 1 8 1H16C16.5523 1 17 1.44772 17 2V5H21C21.5523 5 22 5.44772 22 6V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V6C2 5.44772 2.44772 5 3 5H7ZM9 13H4V19H20V13H15V16H9V13ZM20 7H4V11H9V9H15V11H20V7ZM11 11V14H13V11H11ZM9 3V5H15V3H9Z" fill="#5664D2"/>
                            </svg>
                        </div>
                        <h2 className="analytic mb-40">{campaign_id ? conversions : '...'}</h2>
                        <div className="d-flex align-items-center mt-auto">
                        <div className={'badge badge-pill d-block font-weight-bold my-3 font-nunito py-1 px-3 analytic-badge ' + (increaseConversion > 0 ? 'badge-success' : '') + (increaseConversion === 0 ? 'badge-secondary' : '') + (increaseConversion < 0 ? 'badge-danger' : '')}>{increaseConversion}%</div>
                        <p className="my-0 ml-3 font-nunito">From previous period</p>
                        </div>
                    </div>
                </div>
                {/* <div className=".col-12 col-md-4 pr-0">
                    <div className="card p-4">
                        <div className="d-flex justify-content-between">
                            <p className="font-nunito">CTA Clicked</p>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M7 5V2C7 1.44772 7.44772 1 8 1H16C16.5523 1 17 1.44772 17 2V5H21C21.5523 5 22 5.44772 22 6V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V6C2 5.44772 2.44772 5 3 5H7ZM9 13H4V19H20V13H15V16H9V13ZM20 7H4V11H9V9H15V11H20V7ZM11 11V14H13V11H11ZM9 3V5H15V3H9Z" fill="#5664D2"/>
                            </svg>
                        </div>
                        <h2 className="analytic mb-40">{campaign_id ? ctaClicks : '...'}</h2>
                        <div className="d-flex align-items-center mt-auto">
                        <div className={'badge badge-pill d-block font-weight-bold my-3 font-nunito py-1 px-3 analytic-badge ' + (increaseCta > 0 ? 'badge-success' : '') + (increaseCta === 0 ? 'badge-secondary' : '') + (increaseCta < 0 ? 'badge-danger' : '')}>{increaseCta}%</div>
                        <p className="my-0 ml-3 font-nunito">From previous period</p>
                        </div>
                    </div>
                </div> */}
                {/* <div className=".col-12 col pl-0">
                    <div className="card p-4">
                        <div className="d-flex justify-content-between">
                            <p className="font-nunito">Unique Viewers</p>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M20.083 15.1998L21.285 15.9208C21.4358 16.0111 21.5282 16.174 21.5282 16.3498C21.5282 16.5256 21.4358 16.6885 21.285 16.7788L12.515 22.0408C12.198 22.2312 11.8019 22.2312 11.485 22.0408L2.71498 16.7788C2.56413 16.6885 2.4718 16.5256 2.4718 16.3498C2.4718 16.174 2.56413 16.0111 2.71498 15.9208L3.91698 15.1998L12 20.0498L20.083 15.1998V15.1998ZM20.083 10.4998L21.285 11.2208C21.4358 11.3111 21.5282 11.474 21.5282 11.6498C21.5282 11.8256 21.4358 11.9885 21.285 12.0788L12 17.6498L2.71498 12.0788C2.56413 11.9885 2.4718 11.8256 2.4718 11.6498C2.4718 11.474 2.56413 11.3111 2.71498 11.2208L3.91698 10.4998L12 15.3498L20.083 10.4998ZM12.514 1.30883L21.285 6.57083C21.4358 6.66113 21.5282 6.82402 21.5282 6.99983C21.5282 7.17563 21.4358 7.33852 21.285 7.42882L12 12.9998L2.71498 7.42882C2.56414 7.33852 2.4718 7.17563 2.4718 6.99983C2.4718 6.82402 2.56414 6.66113 2.71498 6.57083L11.485 1.30883C11.8019 1.11841 12.198 1.11841 12.515 1.30883H12.514ZM12 3.33183L5.88696 6.99983L12 10.6678L18.113 6.99983L12 3.33183Z" fill="#5664D2"/>
                            </svg>
                        </div>
                        <h2 className="analytic mb-40">{campaign_id ? uniqueViewers : '...'}</h2>
                        <div className="d-flex align-items-center mt-auto">
                        <div className={'badge badge-pill d-block font-weight-bold my-3 font-nunito py-1 px-3 analytic-badge ' + (uniqueViewer > 0 ? 'badge-success' : '') + (uniqueViewer === 0 ? 'badge-secondary' : '') + (uniqueViewer < 0 ? 'badge-danger' : '')}>{uniqueViewer}%</div>
                        <p className="my-0 ml-3 font-nunito">From previous period</p>
                        </div>
                    </div>
                </div> */}
            </div>
            {showEmailStats &&
            <>
              <h6 className="font-weight-bold mt-0 mb-4 d-flex">
                Email Analytics:
                <div className="ml-auto">
                  <div className="form-inline">
                    <div className="form-group mb-2">
                      <span className="font-nunito mr-4">
                        STEP IN SEQUENCE:
                        {stepInSequenceSelected==='0' && <><br /><small className='text-danger'>To see email tags select step</small></>}
                      </span>
                      <select className="form-control" value={stepInSequenceSelected} onChange={(e) => setstepInSequenceSelected(e.target.value) }>
                        <option value="0">All emails</option>
                        {stepInSequence.length > 0 ? <option value="1">1 - Initial Email</option> : null}
                        {stepInSequence.map((v, index) => {
                          if (index>0)
                            return (<option key={`follow-up-${index}`} value={index+1}>{index+1} - #{index} Follow up Email</option>)
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </h6>
              <div className="row m-0 p-0">
                <div className="col-12 m-0 p-0">
                  <div className="card p-4">
                    <div className="row m-0 p-0">
                      <div className="col">
                        <div className="d-flex justify-content-between">
                          <p className="font-nunito">
                            SENT EMAILS
                            <span style={{verticalAlign: 'middle', position: 'relative'}}>
                              <span onClick={() => {setfilterSentEmail(!filterSentEmail)}} className='ml-3 d-inline-block pointer' style={{width: 14, height: 14, borderRadius: 3, border: '1px solid #CED4DA',zIndex: 5 }}></span>
                              { filterSentEmail && <span onClick={() => {setfilterSentEmail(!filterSentEmail)}} className='pointer' style={{position: 'absolute', bottom: '3px', right: '-5px'}}><AiOutlineCheck size={18} color={'rgba(28, 187, 140, 0.7)'}/></span>}
                            </span>
                          </p>
                        </div>
                        <h2 className="analytic mt-4 mb-4">{sentEmails}</h2>
                      </div>
                      <div className="col">
                        <div className="d-flex justify-content-between">
                          <p className="font-nunito">
                            OPENED
                            <span style={{verticalAlign: 'middle', position: 'relative'}}>
                              <span onClick={() => {setfilterOpenedEmail(!filterOpenedEmail)}} className='ml-3 d-inline-block pointer' style={{width: 14, height: 14, borderRadius: 3, border: '1px solid #CED4DA',zIndex: 5 }}></span>
                              { filterOpenedEmail && <span onClick={() => {setfilterOpenedEmail(!filterOpenedEmail)}} className='pointer' style={{position: 'absolute', bottom: '3px', right: '-5px'}}><AiOutlineCheck size={18} color={'rgba(28, 187, 140, 0.7)'}/></span>}
                            </span>
                          </p>
                        </div>
                        <h2 className="analytic mt-4 mb-4">{getPerOpenedEmails()}% <small className='small-analytics'>{openedEmails}</small></h2>
                      </div>
                      <div className="col">
                        <div className="d-flex justify-content-between">
                          <p className="font-nunito">
                            CLICKED
                            <span style={{verticalAlign: 'middle', position: 'relative'}}>
                              <span onClick={() => {setfilterClickedEmail(!filterClickedEmail)}} className='ml-3 d-inline-block pointer' style={{width: 14, height: 14, borderRadius: 3, border: '1px solid #CED4DA',zIndex: 5 }}></span>
                              { filterClickedEmail && <span onClick={() => {setfilterClickedEmail(!filterClickedEmail)}} className='pointer' style={{position: 'absolute', bottom: '3px', right: '-5px'}}><AiOutlineCheck size={18} color={'rgba(28, 187, 140, 0.7)'}/></span>}
                            </span>
                          </p>
                        </div>
                        <h2 className="analytic mt-4 mb-4">{getPerClickedEmails()}% <small className='small-analytics'>{clickedEmails}</small></h2>
                      </div>
                      <div className="col">
                        <div className="d-flex justify-content-between">
                          <p className="font-nunito">
                            SCHEDULED
                            <span style={{verticalAlign: 'middle', position: 'relative'}}>
                              <span onClick={() => {setfilterScheduledEmail(!filterScheduledEmail)}} className='ml-3 d-inline-block pointer' style={{width: 14, height: 14, borderRadius: 3, border: '1px solid #CED4DA',zIndex: 5 }}></span>
                              { filterScheduledEmail && <span onClick={() => {setfilterScheduledEmail(!filterScheduledEmail)}} className='pointer' style={{position: 'absolute', bottom: '3px', right: '-5px'}}><AiOutlineCheck size={18} color={'rgba(28, 187, 140, 0.7)'}/></span>}
                            </span>
                          </p>
                        </div>
                        <h2 className="analytic mt-4 mb-4">{scheduled}</h2>
                      </div>
                      <div className="col">
                        <div className="d-flex justify-content-between">
                          <p className="font-nunito">
                            UNSUBSCRIBED
                            <span style={{verticalAlign: 'middle', position: 'relative'}}>
                              <span onClick={() => {setfilterUnsubscribedEmail(!filterUnsubscribedEmail)}} className='ml-3 d-inline-block pointer' style={{width: 14, height: 14, borderRadius: 3, border: '1px solid #CED4DA',zIndex: 5 }}></span>
                              { filterUnsubscribedEmail && <span onClick={() => {setfilterUnsubscribedEmail(!filterUnsubscribedEmail)}} className='pointer' style={{position: 'absolute', bottom: '3px', right: '-5px'}}><AiOutlineCheck size={18} color={'rgba(28, 187, 140, 0.7)'}/></span>}
                            </span>
                          </p>
                        </div>
                        <h2 className="analytic mt-4 mb-4">{getPerUnsubscribedEmails()}%</h2>
                      </div>
                      <div className="col">
                        <div className="d-flex justify-content-between">
                          <p className="font-nunito">
                            BOUNCED
                            <span style={{verticalAlign: 'middle', position: 'relative'}}>
                              <span onClick={() => {setfilterBouncedEmail(!filterBouncedEmail)}} className='ml-3 d-inline-block pointer' style={{width: 14, height: 14, borderRadius: 3, border: '1px solid #CED4DA',zIndex: 5 }}></span>
                              { filterBouncedEmail && <span onClick={() => {setfilterBouncedEmail(!filterBouncedEmail)}} className='pointer' style={{position: 'absolute', bottom: '3px', right: '-5px'}}><AiOutlineCheck size={18} color={'rgba(28, 187, 140, 0.7)'}/></span>}
                            </span>
                          </p>
                        </div>
                        <h2 className="analytic mt-4 mb-4">{getPerBouncedEmails()}%</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>}
            <h6 className="font-weight-bold mt-0 mb-4 d-flex">
              People: (filter by video creation <img src={sortIcon} alt="" className='ml-2 mr-2 pointer' onClick={() => {console.log(111);setfilterEmailTable(null);setfilterWatchRateTable(null);filterIdTable === 'ASC' ? setfilterIdTable('DESC') : setfilterIdTable('ASC')}} style={{transform: filterIdTable !== 'DESC' ? 'rotate(180deg)' : '',transition: 'transform 150ms ease', marginTop: '6px', width: '8px', height: '4px'}} />)
              <div className="ml-auto">
                <div className="form-inline">
                  <div className="form-group mb-2 position-relative">
                    <input style={{paddingLeft: '40px'}} type="text" className="form-control" value={filterEmailsNameEmail} placeholder='Search by name or email...' onChange={(e) => setfilterEmailsNameEmail(e.target.value) } />
                    <i style={{position: 'absolute', left: '10px'}} className="mdi mdi-magnify search-icon"></i>
                  </div>
                  <button onClick={() => {analyticsUrlDeb(1, null, null, null, null);}} className="ml-3 btn btn-primary font-nunito">
                    Refresh status
                  </button>
                </div>
              </div>
            </h6>
            <Card>
              <CardBody>
                <div className="table-responsive">
                    <Table className="mb-0">
                        <thead>
                            <tr className="text-uppercase">
                                <th>Name</th>
                                <th>Email <img src={sortIcon} alt="" className='ml-2 pointer' onClick={() => {setfilterIdTable(null);setfilterWatchRateTable(null);filterEmailTable === 'ASC' ? setfilterEmailTable('DESC') : setfilterEmailTable('ASC')}} style={{transform: filterEmailTable !== 'DESC' ? 'rotate(180deg)' : '',transition: 'transform 150ms ease', marginTop: '-3px'}} /></th>
                                <th>ACTIVITY</th>
                                <th>WATCHED <img src={sortIcon} alt="" className='ml-2 pointer' onClick={() => {setfilterIdTable(null);setfilterEmailTable(null);filterWatchRateTable === 'ASC' ? setfilterWatchRateTable('DESC') : setfilterWatchRateTable('ASC')}} style={{transform: filterWatchRateTable !== 'DESC' ? 'rotate(180deg)' : '', transition: 'transform 150ms ease', marginTop: '-3px'}} /></th>
                                <th>LAST ACTIVITY</th>
                                <th>OTHER</th>
                            </tr>
                        </thead>
                        <tbody>
                          {
                            peoples &&
                            peoples.map((item, index) =>
                              <tr className="silver-two" key={`peoples-item-key-${index}`}>
                                <td className="font-weight-bold">
                                  <a href={item.video_url ? item.video_url : null} target="_blank" className='mr-4 d-inline-block'>
                                    <div style={{position: 'relative'}}>
                                      <img src={playIcon} style={{position: 'absolute', top: 14, left: 19}} alt="" />
                                      <img src={item.thumbnail_url ? item.thumbnail_url : item.system_thumbnail_urls ? item.system_thumbnail_urls : null} width="55" height="44"alt="" />
                                    </div>
                                  </a>
                                  <span>{item.name ? item.name : 'N/A'}</span>
                                </td>
                                <td scope="row" className="font-weight-bold">
                                  {item.email}
                                </td>
                                <td>
                                  <div className="d-flex">
                                      {item.page_view ?
                                      <>
                                        <div id={`tooltip-page_view-${index}`} className="badge badge-soft-primary font-size-12 mr-1">Visited</div>
                                        <UncontrolledTooltip target={`tooltip-page_view-${index}`} placement="top" className="analytics-tooltip">
                                          {moment(item.page_view.created_at).format('MMMM Do YYYY, hh:mm:ss A')}
                                        </UncontrolledTooltip>
                                      </> : null}
                                      {item.video_play ?
                                      <>
                                        <div id={`tooltip-video_play-${index}`} className="badge badge-soft-warning font-size-12 mr-1">Watched</div>
                                        <UncontrolledTooltip target={`tooltip-video_play-${index}`} placement="top" className="analytics-tooltip">
                                          {moment(item.video_play.created_at).format('MMMM Do YYYY, hh:mm:ss A')}
                                        </UncontrolledTooltip>
                                      </> : ''}
                                      {item.convers ?
                                      <>
                                        <div id={`tooltip-convers-${index}`} className="badge badge-soft-success font-size-12">Converted</div>
                                        <UncontrolledTooltip target={`tooltip-convers-${index}`} placement="top" className="analytics-tooltip">
                                          {moment(item.convers.created_at).format('MMMM Do YYYY, hh:mm:ss A')}
                                        </UncontrolledTooltip>
                                      </> : ''}
                                  </div>
                                    {item.cta_action ?
                                    <>
                                      <div id={`tooltip-cta_action-${index}`} className="badge badge-soft-info font-size-12 mr-1">CTA action</div>
                                      <UncontrolledTooltip target={`tooltip-cta_action-${index}`} placement="top" className="analytics-tooltip">
                                        {moment(item.cta_action.created_at).format('MMMM Do YYYY, hh:mm:ss A')}
                                      </UncontrolledTooltip>
                                    </> : ''}
                                    {item.page_message ?
                                    <>
                                      <div id={`tooltip-page_message-${index}`} className="badge badge-soft-danger font-size-12 mr-1">Messaged</div>
                                      <UncontrolledTooltip target={`tooltip-page_message-${index}`} placement="top" className="analytics-tooltip">
                                        {moment(item.page_message.created_at).format('MMMM Do YYYY, hh:mm:ss A')}
                                      </UncontrolledTooltip>
                                    </>: ''}
                                    {stepInSequenceSelected!=='0' && !loading &&
                                      <>
                                    <div className="d-flex mt-2">
                                      {item.email_processed !== 1 && item.scheduled_for ?
                                      <>
                                        <div id={`tooltip-scheduled-for-${index}`} className="badge badge-soft-secondary font-size-12 mr-1">Scheduled</div>
                                        <UncontrolledTooltip target={`tooltip-scheduled-for-${index}`} placement="top" className="analytics-tooltip">
                                          ~{moment(item.scheduled_for).format('MMMM Do YYYY, hh:mm:ss A')}
                                        </UncontrolledTooltip>
                                      </> : null}
                                      {item.schedule_sent_publish_email && item.schedule_sent_publish_email.length && item.schedule_sent_publish_email[0].publish_email_id ?
                                      <>
                                        <div id={`tooltip-schedule_sent_publish_email-${index}`} className="badge badge-soft-success font-size-12 mr-1">Email sent</div>
                                        <UncontrolledTooltip target={`tooltip-schedule_sent_publish_email-${index}`} placement="top" className="analytics-tooltip">
                                          {moment(item.schedule_sent_publish_email[0].created_at).format('MMMM Do YYYY, hh:mm:ss A')}
                                        </UncontrolledTooltip>
                                      </> : ''}
                                    </div>
                                    <div className="d-flex mt-2">
                                      {checkOpened(item.schedule_sent_publish_email) ?
                                      <>
                                        <div id={`tooltip-opened-${index}`} className="badge badge-warning font-size-12 mr-1">Opened</div>
                                        <UncontrolledTooltip target={`tooltip-opened-${index}`} placement="top" className="analytics-tooltip">
                                          {moment(item.schedule_sent_publish_email[0].opened).format('MMMM Do YYYY, hh:mm:ss A')}
                                        </UncontrolledTooltip>
                                      </> : null}
                                      {checkClicked(item.schedule_sent_publish_email) ?
                                        <>
                                          <div id={`tooltip-clicked-${index}`} className="badge badge-primary font-size-12 mr-1">Clicked</div>
                                          <UncontrolledTooltip target={`tooltip-clicked-${index}`} placement="top" className="analytics-tooltip">
                                            {moment(item.schedule_sent_publish_email[0].clicked).format('MMMM Do YYYY, hh:mm:ss A')}
                                          </UncontrolledTooltip>
                                        </> : ''}
                                      {checkUnsubscribed(item.schedule_sent_publish_email) ?
                                        <>
                                          <div id={`tooltip-unsubscribed-${index}`} className="badge badge-secondary font-size-12 mr-1">Unsubscribed</div>
                                          <UncontrolledTooltip target={`tooltip-unsubscribed-${index}`} placement="top" className="analytics-tooltip">
                                            {moment(item.schedule_sent_publish_email[0].unsubscribed).format('MMMM Do YYYY, hh:mm:ss A')}
                                          </UncontrolledTooltip>
                                        </> : ''}
                                      {checkBounced(item.schedule_sent_publish_email) ?
                                      <>
                                        <div id={`tooltip-bounced-${index}`} className="badge badge-danger font-size-12 mr-1">Bounced</div>
                                        <UncontrolledTooltip target={`tooltip-bounced-${index}`} placement="top" className="analytics-tooltip">
                                          {moment(item.schedule_sent_publish_email[0].created_at).format('MMMM Do YYYY, hh:mm:ss A')}
                                        </UncontrolledTooltip>
                                      </>: ''}
                                    </div>
                                  </>}
                                </td>
                                <td>
                                    <div>
                                        <p>{(item.video_play && item.video_play.rate) ? item.video_play.rate : 0}% <small style={{marginLeft: '20px'}}>played <strong>{(item.video_play && item.video_play.count_plays) ? item.video_play.count_plays : 0}</strong> time(s)</small></p>
                                        <div className="progress animated-progess w-50">
                                          <div className="progress-bar bg-primary " role="progressbar" style={{width: ( item.video_play ? item.video_play.rate : 0) + '%'}} aria-valuenow={item.rate} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                  {getDate(item.last_activity)}
                                </td>
                                <td>
                                    <div className="d-flex">
                                        { item.page_message ?
                                          <div className="mr-4 cursor-pointer" onClick={() => readMessage(item.page_message.message)}>
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M5.51555 11.5405L6.18305 13.2157L8.5193 11.5405H13C13.2761 11.5405 13.5 11.3158 13.5 11.0386V3.00772C13.5 2.73051 13.2761 2.50579 13 2.50579H3C2.72386 2.50579 2.5 2.73051 2.5 3.00772V11.0386C2.5 11.3158 2.72386 11.5405 3 11.5405H5.51555ZM9 13.0463H13C14.1046 13.0463 15 12.1474 15 11.0386V3.00772C15 1.89888 14.1046 1 13 1H3C1.89543 1 1 1.89889 1 3.00772V11.0386C1 12.1474 1.89543 13.0463 3 13.0463H4.5L5.02689 14.3686C5.2677 14.973 6.00897 15.191 6.5366 14.8126L9 13.0463ZM3.75 5.01543C3.75 4.59962 4.08579 4.26254 4.5 4.26254H11.5C11.9142 4.26254 12.25 4.59962 12.25 5.01543C12.25 5.43124 11.9142 5.76832 11.5 5.76832H4.5C4.08579 5.76832 3.75 5.43124 3.75 5.01543ZM4.5 7.57532C4.08579 7.57532 3.75 7.9124 3.75 8.32821C3.75 8.74402 4.08579 9.0811 4.5 9.0811H9.5C9.91421 9.0811 10.25 8.74402 10.25 8.32821C10.25 7.9124 9.91421 7.57532 9.5 7.57532H4.5Z" fill="#171F46"/>
                                            </svg>
                                        </div> : null
                                        }
                                        <div className="mr-4 cursor-pointer" onClick={() => {openVideo(item)}}>
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M14.5 8C14.5 11.5899 11.5899 14.5 8 14.5C4.41015 14.5 1.5 11.5899 1.5 8C1.5 4.41015 4.41015 1.5 8 1.5C11.5899 1.5 14.5 4.41015 14.5 8ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM6.80005 10.6C6.80005 10.8472 7.08228 10.9883 7.28005 10.84L10.48 8.43995C10.64 8.31995 10.64 8.07995 10.48 7.95995L7.28005 5.55995C7.08228 5.41162 6.80005 5.55274 6.80005 5.79995V10.6Z" fill="#171F46"/>
                                            </svg>

                                        </div>
                                        <div className="cursor-pointer">
                                          <span className='pointer' title='Resend email'>
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M7.85 7.97342L1.75272 3.57088C1.77005 3.56717 1.78803 3.56522 1.80645 3.56522H14.1935C14.212 3.56522 14.2299 3.56717 14.2473 3.57088L8.15 7.97342C8.06027 8.03821 7.93973 8.03821 7.85 7.97342ZM10.6086 8.12172L14.4516 5.34684V10.7116L10.6086 8.12172ZM9.04998 9.24709L9.26099 9.09473L14.2158 12.4338C14.2085 12.4345 14.201 12.4348 14.1935 12.4348H1.80645C1.79896 12.4348 1.79154 12.4345 1.78421 12.4338L6.739 9.09473L6.95002 9.24709C7.57812 9.70061 8.42188 9.70061 9.04998 9.24709ZM5.39143 8.12172L1.54839 5.34684V10.7116L5.39143 8.12172ZM1.80645 2C0.808777 2 0 2.81757 0 3.82609V12.1739C0 13.1824 0.808776 14 1.80645 14H14.1935C15.1912 14 16 13.1824 16 12.1739V3.82609C16 2.81757 15.1912 2 14.1935 2H1.80645Z" fill="#171F46"/>
                                            </svg>
                                          </span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            )
                          }
                          {!peoples &&
                            !peoples.length
                            ?
                              <tr className="silver-two">
                                <td scope="row" colSpan="5" className="font-weight-bold">Check back soon</td>
                              </tr>
                            :
                            null
                          }
                        </tbody>
                    </Table>
                    <div className="dataTables_paginate ml-auto mr-3">
                      <ul data-test="pagination" className="pagination">
                        {
                          pagesGallery && pagesGallery.links &&
                          pagesGallery.links.map((item, index) => {
                            return (
                              <li className={'page-item' + (item.url ? '': ' disabled') + (item.active ? ' active': '')} key={`peoples-pagination-library-${index}`}>
                                <button onClick={(e) => {doGetAnalytics(item.url.split('?page=').pop())}} className="page-link page-link"><span dangerouslySetInnerHTML={{__html: item.label}} ></span></button>
                              </li>
                            )
                          })
                        }
                      </ul>
                    </div>
                </div>
              </CardBody>
          </Card>
                <Modal isOpen={modal} toggle={toggleModal} size={"lg"}>
                  <ModalBody className="px-4 py-5">
                    <div className="embed-responsive embed-responsive-21by9">
                        <video src={videoSrc} controls preload="auto" className="embed-responsive-item"></video>
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="primary" className="px-5" onClick={toggleModal}>Close</Button>{' '}
                  </ModalFooter>
                </Modal>
                <Modal isOpen={messageModal} toggle={toggleMessageModal} size={"lg"}>
                  <ModalBody className="px-4 py-5">
                    {message}
                  </ModalBody>
                  <ModalFooter>
                    <Button color="primary" className="px-5" onClick={toggleMessageModal}>Close</Button>{' '}
                  </ModalFooter>
                </Modal>
        </div></> );
}

export default AnalyticsShared;