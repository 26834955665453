import React, { useState, useEffect, useRef } from 'react';
import videojs from 'video.js';
import '@devmobiliza/videojs-vimeo/dist/videojs-vimeo.cjs';
import { useParams } from "react-router-dom";

import {
    getLandingPageByVideoPersonalizationId,
    getVideoByUniqueId,
    pageView,
    videoView,
  } from "../services/API";

const demoVideoSrc = '/videoplaceholder.mp4';

const VideoFull = () => {

  const playerRef = useRef();
  const vidIdRef = useRef();
  const vidUidRef = useRef();
  const [player, setPlayer] = useState(null);
  const [nextVideoSrc, setNextVideoSrc] = useState(null);
  const [videoSrc, setVideoSrc] = useState(null);
  const [clickedPlay, setClickedPlay] = useState(false);
  const [init, setInit] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [paramsVar, setParamsVar] = useState(null);
  const [campaignId, setCampaignId] = useState(null);
  const [videoId, setVideoId] = useState(null);
  const [sentRate, setSentRate] = useState(false);
  const [videoPlayId, setVideoPlayId] = useState(null);
  const [played, setPlayed] = useState(0);
  const [videoPersonalization, setvideoPersonalization] = useState(null);
  const { videoPersonalizationId, videoUUID, uuid } = useParams();
  const [paused, setPaused] = useState(false);
  const [ videoUUIDSet, setVideoUUIDSet ] = useState(null);

  vidIdRef.current = videoPlayId;
  vidUidRef.current = videoUUIDSet;

  let video;

  useEffect(() => {

        let params = getSearchParameters();
        setParamsVar(params);

        if (!init && videoPersonalizationId) {

          let cliked = null;

          if (params.pblsheml)
            cliked=params.pblsheml;

            document.body.className += ' video-player';

            getLandingPageByVideoPersonalizationId(videoPersonalizationId, cliked)
                .then((resp) => {

                    if (resp.status) {

                        setCampaignId(resp.campaign.id);
                        let campaign_id = resp.campaign.id;

                        setNextVideoSrc(resp.video_personalization.next_video_url);
                        setvideoPersonalization(resp.video_personalization);

                          if (videoUUID || params.uuid || params.email) {

                            let uuidGet = videoUUID ? videoUUID : (params.uuid ? params.uuid : null);

                              getVideoByUniqueId(uuidGet, decodeURIComponent(params.email), videoPersonalizationId)
                                .then((resp) => {
                                  if (resp.status) {
                                    let video = resp.video;
                                    setVideoSrc(video.video_url);
                                    setVideoId(video.id);
                                    window.parent.postMessage('v_id=' + video.id,'*');
                                    setVideoUUIDSet(video.email);

                                    //Add page view in analitycs
                                    let name = params.firstName || params.name || params.fullName;

                                    pageView(campaign_id, video.id, decodeURIComponent(params.email), name, videoUUID);
                                  }
                                  setLoaded(true);
                                })
                                .catch(function (resp) {
                                });
                            } else {
                              if (resp.video_personalization.video && resp.video_personalization.video.video_url)
                                setVideoSrc(resp.video_personalization.video.video_url);
                              else
                                setVideoSrc(resp.video_personalization.video_url);
                              setLoaded(true);
                            }
                    }
                })
                .catch();

        }

        setInit(true);

  }, [videoSrc]);

  useEffect(() => {

    if (!loaded)
      return;

    if (!video) {
      video = videojs(playerRef.current, {
        autoplay: true,
        muted: true,
        techOrder: ['html5', 'vimeo'],
        controlBar: {
          fullscreenToggle: false
        },
        userActions: {
            doubleClick: false
        }
      }, () => {

      });
  }

  if (videoSrc) {
    if (videoSrc.includes('vimeo')) {
      video.src({
        'type': 'video/vimeo',
        src: videoSrc
      });
    } else {
      video.src({ src: videoSrc });
    }
  } else if (loaded) {
    if (demoVideoSrc.includes('vimeo')) {
      video.src({
        'type': 'video/vimeo',
        src: demoVideoSrc
      });
    } else {
      video.src({ src: demoVideoSrc });
    }
    setVideoSrc(demoVideoSrc);
  }

  setPlayer(video);
  video.on('play', () => {setPaused(false);});
  video.on('pause', () => {setPaused(true);});

  video.on('touchstart', function (e) {
    togglePause(e);
  });

  let isOnIOS = navigator.userAgent.match(/iPad/i)|| navigator.userAgent.match(/iPhone/i);
  let eventName = isOnIOS ? "pagehide" : "beforeunload";

  window.addEventListener(eventName, function (event) {
    window.event.cancelBubble = true;
    sendRate();
  });

  }, [loaded]);

  function togglePause() {

    if (!player)
      return;

    if (player.paused())
      player.play();
    else
      player.pause();
  }

  function getSearchParameters() {
    var prmstr = window.location.search.substr(1);
    return prmstr != null && prmstr != "" ? transformToAssocArray(prmstr) : {};
  }

    function transformToAssocArray( prmstr ) {
        var params = {};
        var prmarr = prmstr.split("&");
        for ( var i = 0; i < prmarr.length; i++) {
            var tmparr = prmarr[i].split("=");
            params[tmparr[0]] = decodeURI(tmparr[1]);
        }
        return params;
    }

    function sendRate(finished=false) {

        let time = document.getElementById('videoLead_html5_api').currentTime;
        let duration = document.getElementById('videoLead_html5_api').duration;

        if (sentRate || !vidIdRef)
          return;
        if (time>0 && vidUidRef) {

            let percentage = Math.round((time/duration) * 100);
            if (finished)
                percentage = 100;

            fetch(process.env.REACT_APP_API_URL + 'analitycs/video-rate/' + vidIdRef.current + '/' + percentage, {
                method: "GET",
                headers: {
                    "Accept": "application/json"
                },
                keepalive: true,
                });
                setSentRate(true);
        }

      }

      function playVideo() {

        if (player) {

          player.ready(function() {
            if (player.paused() || !clickedPlay) {
              player.currentTime(0);
              player.play(true);
              player.muted(false);
              setClickedPlay(true);

              //Add video view in analitycs
              if (!played && videoUUIDSet) {
                videoView(campaignId, videoId, decodeURIComponent(paramsVar.email), videoUUIDSet)
                  .then((resp) => {
                    if (resp.status)
                      setVideoPlayId(resp.video_play_id.id)
                  });
                setPlayed(played+1);
              }
            } else {
              player.pause();
            }

          });

          player.on("ended", playNextVideo);

        }
      }

  function playNextVideo() {

    if ((player.currentSrc() !== nextVideoSrc && nextVideoSrc) && (videoPersonalization && !videoPersonalization.transition_video_merging)) {

      if (nextVideoSrc.includes('vimeo')) {
        player.src({
          'type': 'video/vimeo',
          src: nextVideoSrc
        });
      } else {
        player.src({
          src: nextVideoSrc
        });
      }

      player.ready(function() {
        player.currentTime(0);
        player.play();
      });

    } else {
      sendRate(true);
    }
  }

  function doPlayVideo(e) {
    e.stopPropagation();
    player.play(true)
  }

  return ( <div className="player" onClick={() => {togglePause()}}>
            <video id="videoLead" ref={playerRef} autoPlay preload="auto" muted playsInline data-setup='{"fluid": true}' className="video-js vjs-default-skin vjs-16-9"></video>
            {!clickedPlay ? <div className="play-big-btn" onClick={() => {playVideo()}}></div> : null}
            {clickedPlay && paused ? <div className="play-big-btn" onClick={(e) => {doPlayVideo(e)}}></div> : null}
          </div>
    );
}

export default VideoFull;