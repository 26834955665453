import React, { useLayoutEffect } from 'react';
import { useParams } from "react-router-dom";

import {
  unsubscribeFromVideo,
} from "../services/API";

const Unsubscribe = () => {

  const { videoPersonalizationId, videoUUID } = useParams();

  useLayoutEffect(() => {
    unsubscribeFromVideo(videoPersonalizationId, videoUUID)
      .then((resp) => {
        console.log(resp)
      })
      .catch((resp) => {
        window.close();
      });
  }, []);

  return ( <>
            <div className='h-100 d-flex justify-content-center align-items-center' style={{minHeight: '100vh'}}>
              <h1 className='text-success' style={{fontSize: '48px'}}>You have been unsubscribed successfully.</h1>
            </div>
          </>
    );
}

export default Unsubscribe;