import React, { useState, useEffect, useRef } from 'react';
import videojs from 'video.js';
import '@devmobiliza/videojs-vimeo/dist/videojs-vimeo.cjs';

const VideoTest = () => {

  const playerRef = useRef();
  const [sorce, setsorce] = useState('');

  useEffect(() => {

    if (!sorce)
      return;

    var video;

    //video js setup
    if (!video) {
      video = videojs(playerRef.current, {
        autoplay: true,
        muted: true,
        controlBar: {
          fullscreenToggle: false
        },
        techOrder: ['html5', 'vimeo'],
        userActions: {
            doubleClick: false
        }
      }, () => {

      });
    }

    if (sorce && sorce.includes('vimeo')) {
      video.src({
        'type': 'video/vimeo',
        src: sorce
      });
    } else if (sorce) {
      video.src({ src: sorce });
    }

  }, [sorce]);

  return (
    <div className="row d-flex" style={{height: '100vh'}}>
      <div className="col-12 d-flex align-items-center justify-content-center">
        <input type="text" className='m-5' style={{width: '640px'}} value={sorce} onChange={e => setsorce(e.target.value)} />
      </div>
      <div className="col-12 d-flex align-items-center justify-content-center">
        <div className="player" style={{width:640, height: 480}}>
          <video id="videoLead" ref={playerRef} autoPlay preload="auto" muted playsInline data-setup='{"fluid": true}' className="video-js vjs-default-skin vjs-16-9"></video>
        </div>
      </div>
    </div>
  );
}

export default VideoTest;