import React, { useState, useEffect, useRef } from 'react';
import { useParams } from "react-router-dom";
import videojs from 'video.js';
import 'videojs-errors';
import '@devmobiliza/videojs-vimeo/dist/videojs-vimeo.cjs';

import Bridget from './assets/bridget.png';
import AnnaCostume1Large from './assets/anna_costume1.png';
import Howard from './assets/horward.png';
import Jack from './assets/jack.png';
import Laura from './assets/laura.png';
import Sofia from './assets/mia.png'; //this is sofia
import Vincet from './assets/vincet.png';

import {
  getLandingPageByVideoPersonalizationId,
  getVideoByUniqueId,
  convertedLead,
  videoView,
  ctaAction,
  pageView,
  pageMessage,
  getMessages,
} from "./services/API";

const demoVideoSrc = '/videoplaceholder.mp4';

const Home = () => {

  const vidIdRef = useRef();
  const vidUidRef = useRef();
  const playerRef = useRef();
  const [logoImage, setLogoImage] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState('none');
  const [clickedPlay, setClickedPlay] = useState(false);
  const [init, setInit] = useState(false);
  const [enableChat, setEnableChat] = useState(false);
  const [enableButton, setEnableButton] = useState(false);
  const [raBranding, setRaBranding] = useState(false);
  const [buttonText, setButtonText] = useState('Book a call');
  const [welcomeMessage, setWelcomeMessage] = useState('');
  const [buttonUrl, setButtonUrl] = useState('#');
  const [emailAddress, setEmailAddress] = useState('');
  const [videoSrc, setVideoSrc] = useState(null);
  const [nextVideoSrc, setNextVideoSrc] = useState(null);
  const [landingPage, setLandingPage] = useState(null);
  const [campaignId, setCampaignId] = useState(null);
  const [videoId, setVideoId] = useState(null);
  const [paramsVar, setParamsVar] = useState(null);
  const [player, setPlayer] = useState(null);
  const [message, setMessage] = useState('');
  const [played, setPlayed] = useState(0);
  const [videoPlayId, setVideoPlayId] = useState(null);
  const [sentRate, setSentRate] = useState(false);
  const { videoUUID, landingPageId } = useParams();
  const [ videoUUIDSet, setVideoUUIDSet ] = useState(null);
  const [choseActorImage, setChoseActorImage] = useState(null);
  const [name, setName] = useState(null);
  const [jobTitle, setJobTitle] = useState(null);
  const [paused, setPaused] = useState(false);
  const [messages, setMessages] = useState([false]);
  const [videoPersonalization, setVideoPersonalization] = useState(null);
  const [campaign, setCampaig] = useState(null);
  const [refr, setRefr] = useState(0);
  const [loading, setLoading] = useState(false);
  const [clientName, setClientName] = useState(false);
  const [state, setState] = useState(0);

  vidIdRef.current = videoPlayId;
  vidUidRef.current = videoUUIDSet;

  let params = getSearchParameters();

  useEffect(() => {

    let params = getSearchParameters();
    setParamsVar(params);

    document.body.className += ' client-page';

    //override state as it is async
    if (params.iframed)
      document.body.className += ' iframed';

    if (!init) {

      let campaign_id;

      let cliked = null;

      if (params.pblsheml)
        cliked=params.pblsheml;

      let lndPAgeId = landingPageId;

      if (lndPAgeId.indexOf('?')>-1) {
        lndPAgeId = lndPAgeId.split('?')[0];
      }

      //for demo get video personalization
      getLandingPageByVideoPersonalizationId(lndPAgeId, cliked)
        .then((resp) => {
          setLoading(false);

          let landingPage = resp.landing_page;
          setLandingPage(landingPage);
          let videoPersonalization = resp.video_personalization;

          console.log(resp)

          setVideoPersonalization(videoPersonalization);

          setChoseActorImage(videoPersonalization.actor);

          setNextVideoSrc(videoPersonalization.next_video_url);

          setVideoSrc(videoPersonalization.video.video_url);

          if (resp.status && landingPage) {
            setCampaignId(resp.campaign.id);
            campaign_id = resp.campaign.id;
            setCampaig(resp.campaign);

            if (landingPage.name)
              setName(landingPage.name);

            if (landingPage.job_title)
              setJobTitle(landingPage.job_title);

            if (landingPage.logo_url)
              setLogoImage(landingPage.logo_url);

            if (landingPage.backgorund_image_url)
              setBackgroundImage(landingPage.backgorund_image_url);

            setEnableButton(landingPage.button_cta);
            setButtonText(landingPage.button_name);
            doSetButtonUrl(landingPage.button_link);
            setEnableChat(landingPage.show_chat_box);
            setEmailAddress(landingPage.email);

            if (!landingPage.email)
              setEnableChat(false);

            setRaBranding(landingPage.ra_branding);

            if (landingPage.message) {
                const matches = landingPage.message.match(/({{)(.*?)(}})/g);

                let i;
                let j;
                let splited;
                let srch;
                let tmpMessage = landingPage.message;
                let changed = false;

                for (i in matches) {

                  changed = false;

                  splited = matches[i].split('|');

                  //replace vars in welcome message if they exist in search query to do
                  for (j in params) {
                    srch = splited[0].replace('{{', '')
                    if (j && srch && j.toLowerCase() == srch.toLowerCase()) {
                      tmpMessage = tmpMessage.replace(matches[i], params[j]);
                      changed = true;
                    }


                  }

                  if (!changed) {
                    if (splited && splited.length && splited[1]) {
                      tmpMessage = tmpMessage.replace(matches[i], splited[1]);
                      tmpMessage = tmpMessage.replace('}}', '');
                    }
                  }

                }

                tmpMessage = tmpMessage.replace(/({{)(.*?)(}})/g, '');

                setWelcomeMessage(tmpMessage);

            }

            if ((videoUUID || params.email) /*&& !landingPageId*/) {
                getVideoByUniqueId(videoUUID, decodeURIComponent(params.email), videoPersonalization.id)
                  .then((resp) => {
                    if (resp.status) {

                        let video;

                        if (resp.video)
                          video = resp.video;
                        else if (resp.video_personalization && resp.video_personalization.video)
                          video = resp.video_personalization.video;

                        setVideoSrc(video.video_url);

                        if (video.system_thumbnail_urls) {
                          var link = document.createElement('meta');
                          link.setAttribute('property', 'og:url');
                          link.content = video.system_thumbnail_urls;
                          document.getElementsByTagName('head')[0].appendChild(link);
                        }

                        setVideoId(video.id);
                        let data = JSON.parse(video.data);

                        if (video.email)
                          setVideoUUIDSet(video.email);
                        else if (video.data && tryParseJSONObject(video.data)) {
                          let dataParsed = JSON.parse(video.data);
                          if (dataParsed.email)
                            setVideoUUIDSet(dataParsed.email);
                        }

                        //Add page view in analitycs
                        let name = null;

                        if (params.firstName || params.name || params.fullName) {
                          name = params.firstName || params.name || params.fullName;
                        } else {
                          let i;
                          for (i in data) {
                            if (i.toLocaleLowerCase() == 'firstname' || i.toLocaleLowerCase() == 'name' || i.toLocaleLowerCase() == 'fullName')
                              name = data[i];
                          }
                        }

                        pageView(campaign_id, video.id, decodeURIComponent(params.email), name, video.uuid);

                        setClientName(name);

                        //set name params dynamic

                        if (landingPage.message) {
                          const matches = landingPage.message.match(/({{)(.*?)(}})/g);

                          let i;
                          let j;
                          let splited;
                          let srch;
                          let tmpMessage = landingPage.message;
                          let changed = false;

                          for (i in matches) {

                            changed = false;

                            splited = matches[i].split('|');

                            //replace vars in welcome message if they exist in search query to do
                            for (j in data) {
                              srch = splited[0].replace('{{', '').replace('}}', '');
                              if (j && srch && j.toLowerCase() == srch.toLowerCase()) {
                                tmpMessage = tmpMessage.replace(matches[i], data[j]);
                                changed = true;
                              }

                              if ((j.toLowerCase() == 'firstname' || j.toLowerCase() == 'name' || j.toLowerCase() == 'fullName') && !clientName)
                                setClientName(data[j]);

                            }

                            if (!changed) {
                              if (splited && splited.length && splited[1]) {
                                tmpMessage = tmpMessage.replace(matches[i], splited[1]);
                                tmpMessage = tmpMessage.replace('}}', '');
                              }
                            }

                          }

                          tmpMessage = tmpMessage.replace(/({{)(.*?)(}})/g, '');

                          if (video.api_welcome_message)
                            setWelcomeMessage(video.api_welcome_message);
                          else
                            setWelcomeMessage(tmpMessage);
                        }
                    }
                    setLoaded(true);
                  })
                  .catch(function (resp) {
                    console.log(resp)
                  });

            } else {
              setLoaded(true);
            }

          } else {
            setLoaded(true);
          }

          if (params.showChatBox === 'true')
            setEnableChat(true);
              else if (params.showChatBox === 'false')
                setEnableChat(false);

          if (params.showCta === 'true')
            setEnableButton(true);
              else if (params.showCta === 'false')
                setEnableButton(false);

          if (params.raBranding === 'true')
            setRaBranding(true);
              else if (params.raBranding === 'false')
                setRaBranding(false);

          if (params.logo)
            setLogoImage(params.logo);

          if (params.backgoundImage)
            setBackgroundImage(params.backgoundImage);

          if (params.receiversEmail)
            setEmailAddress(params.receiversEmail);

          if (params.buttonName)
            setButtonText(params.buttonName);

          if (params.setName)
            setName(params.setName);

          if (params.setJobTitle)
            setJobTitle(params.setJobTitle);

          if (params.buttonLink)
            doSetButtonUrl(params.buttonLink);

          if (params.welcommeMessage) {

            const matches = params.welcommeMessage.match(/({{)(.*?)(}})/g);

            let i;
            let splited;
            let tmpMessage = params.welcommeMessage;

            for (i in matches) {
              splited = matches[i].split('|');

              if (splited && splited.length && splited[1])
                splited = splited[1].replace('}}', '');

              tmpMessage = tmpMessage.replace(matches[i], splited);
            }

            tmpMessage = tmpMessage.replace(/({{)(.*?)(}})/g, '');

            setWelcomeMessage(tmpMessage);
          }

          if (landingPage && landingPage.enable_tracking) {

            if (landingPage.header_tracking_code) {

              let tmpDOM = document.createElement('div');
              tmpDOM.innerHTML = landingPage.header_tracking_code;

              let getScript = tmpDOM.getElementsByTagName( 'script' )[0].innerHTML;

              let script = document.createElement('script');
              script.innerHTML = getScript;

              document.getElementsByTagName('head')[0].appendChild(script);
            }

            if (landingPage.footer_tracking_code) {

              let tmpDOM = document.createElement('div');
              tmpDOM.innerHTML = landingPage.footer_tracking_code;

              let getScript = tmpDOM.getElementsByTagName( 'script' )[0].innerHTML;

              let script = document.createElement('script');
              script.innerHTML = getScript;

              document.getElementsByTagName('body')[0].appendChild(script);
            }

          }

        })
        .catch((resp) => {
          console.log(resp)
        });

        let isOnIOS = navigator.userAgent.match(/iPad/i)|| navigator.userAgent.match(/iPhone/i);
        let eventName = isOnIOS ? "pagehide" : "beforeunload";

        window.addEventListener(eventName, function (event) {
          window.event.cancelBubble = true;
          sendRate();
        });

        setInit(true);

    }

    return () => {
      document.body.className = '';
    }

  }, [videoSrc, refr]);

  useEffect(() => {

    if (!loaded)
      return;

    var video;

    //video js setup
    if (!video) {
      video = videojs(playerRef.current, {
        autoplay: true,
        muted: true,
        techOrder: ['html5', 'vimeo'],
        controlBar: {
          fullscreenToggle: false
        },
        userActions: {
            doubleClick: false
        }
      }, () => {

      });
    }

    if (videoSrc) {
      if (videoSrc.includes('vimeo')) {
        video.src({
          'type': 'video/vimeo',
          src: videoSrc
        });
      } else {
        video.src({ src: videoSrc });
      }
    } else if (loaded) {
      if (demoVideoSrc.includes('vimeo')) {
        video.src({
          'type': 'video/vimeo',
          src: demoVideoSrc
        });
      } else {
        video.src({ src: demoVideoSrc });
      }
      setVideoSrc(demoVideoSrc);
    }

    setPlayer(video);

    video.on('play', () => {setPaused(false);});
    video.on('pause', () => {setPaused(true);});

    video.errors({
        errors: {
            4: {
                headline: 'Video IN PROGRESS',
                message: 'Video status is still in progress. Please be patient.',
                type: 'IN PROGRESS'
            }
          }
    });

    video.on('touchstart', function (e) {
      togglePause(e);
    });

  }, [loaded]);

  useEffect(() => {

    document.getElementById('chatBox').scrollTop = document.getElementById('chatBox').scrollHeight;

  }, [messages]);

  function togglePause(e) {

    if (!player)
      return;

    if (player.paused())
      player.play();
    else
      player.pause();
  }

  const tryParseJSONObject = (jsonString) => {
    try {
        var o = JSON.parse(jsonString);

        // Handle non-exception-throwing cases:
        // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
        // but... JSON.parse(null) returns null, and typeof null === "object",
        // so we must check for that, too. Thankfully, null is falsey, so this suffices:
        if (o && typeof o === "object") {
            return o;
        }
    }
    catch (e) { }

    return false;
};

  const doSetButtonUrl = (url) => {

    if (!/^https?:\/\//i.test(url)) {
      url = 'https://' + url;
    }
    setButtonUrl(url);
  }

  function actorImage() {
    let img = null;

    if (choseActorImage === 'bridget_costume1_cameraA')
      img = Bridget;

    if (choseActorImage === 'howard_costume1_cameraA')
      img = Howard;

  if (choseActorImage === 'anna_costume1_cameraA')
      img = AnnaCostume1Large;

    if (choseActorImage === 'jack_costume1_cameraA')
      img = Jack;

    if (choseActorImage === 'laura_costume1_cameraA')
      img = Laura;

    if (choseActorImage === 'sofia_costume1_cameraA')
      img = Sofia;

    if (choseActorImage === 'vincent_costume1_cameraA')
      img = Vincet;

    return img;

  }

  function sendRate(finished=false) {

    let time = document.getElementById('videoLead_html5_api').currentTime;
    let duration = document.getElementById('videoLead_html5_api').duration;

    if (sentRate || !vidIdRef)
      return;

    if (time>0 && vidUidRef) {
      let percentage = Math.round((time/duration) * 100);
      if (finished)
        percentage = 100;

      fetch(process.env.REACT_APP_API_URL + 'analitycs/video-rate/' + vidIdRef.current + '/' + percentage, {
          method: "GET",
          headers: {
            "Accept": "application/json"
          },
          keepalive: true,
        });
        setSentRate(true);
    }

  }

  function getSearchParameters() {
    var prmstr = window.location.search.substr(1);
    return prmstr != null && prmstr != "" ? transformToAssocArray(prmstr) : {};
  }

  function transformToAssocArray( prmstr ) {
      var params = {};
      var prmarr = prmstr.split("&");
      for ( var i = 0; i < prmarr.length; i++) {
          var tmparr = prmarr[i].split("=");
          params[tmparr[0]] = decodeURI(tmparr[1]);
      }
      return params;
  }

  function playVideo() {

    if (player) {

      player.ready(function() {
        if (player.paused() || !clickedPlay) {
          player.currentTime(0);
          player.play(true);
          player.muted( false );
          setClickedPlay(true);

          //Add video view in analitycs
          if (!played && videoUUIDSet) {
            videoView(campaignId, videoId, decodeURIComponent(paramsVar.email), videoUUIDSet)
              .then((resp) => {
                if (resp.status)
                  setVideoPlayId(resp.video_play_id.id)
              });
            setPlayed(played+1);
          }
        } else {
          player.pause();
        }

      });

      player.on("ended", playNextVideo);

    }
  }

  function playNextVideo() {

    if ((player.currentSrc() !== nextVideoSrc && nextVideoSrc) && (videoPersonalization && !videoPersonalization.transition_video_merging)) {

      if (nextVideoSrc.includes('vimeo')) {
        player.src({
          'type': 'video/vimeo',
          src: nextVideoSrc
        });
      } else {
        player.src({
          src: nextVideoSrc
        });
      }

      player.ready(function() {
        player.currentTime(0);
        player.play();
      });

    } else {
      sendRate(true);
    }
  }

  function buttonAction(e) {

    convertedLead(campaignId, videoUUIDSet)
      .then(() => {
        //Add CTA action in analitycs
        ctaAction(campaignId, videoId, decodeURIComponent(paramsVar.email), videoUUIDSet)
          .then((resp) => {
          });
      })
      .catch(() => {
        window.location.href = buttonUrl;
      });

  }

  function sendMessage(e) {

    setLoading(true);

    e.preventDefault();
    pageMessage(campaignId, videoId, decodeURIComponent(paramsVar.email), message, videoUUIDSet)
      .then((resp) => {

        setMessage('');

        getMessages(videoId)
          .then((resp) => {
            if (resp.status) {
              setMessages(resp.messages);
              setLoading(false);
            }
          })
          .catch();

      })
      .catch((resp) =>{
        setMessage('');
      });
  }

  function doPlayVideo(e) {
    e.stopPropagation();
    player.play(true)
  }

  return (
    <div className="container-fluid page-content chat d-flex flex-column justify-content-center align-items-center" style={{ background: `url(${backgroundImage})`}}>
        { loading ?
          <div className="container-fluid page-content loader-wrapper">
            <div className="loader"></div>
        </div>
        :
        null
        }
        <div className="w-100 d-flex flex-column">
            <img className="mx-auto text-center img-fluid logo" height="20" src={logoImage} alt="" />
            <div className="chat-box container">
                <div className="col-12 col-md-12 mx-auto card p-0 lead-page-main">
                    <div className="row m-0 p-0">
                        <div className="col-md-12 col-lg-8 pl-0 pr-0 lead-page-left">
                            <div className={'background-chat ' + (!videoSrc ? 'invisible' : '')} onClick={(e) => {togglePause(e)}}>
                              <video playsInline autoPlay preload="auto" muted data-setup='{"fluid": true}' ref={playerRef} className="video-js vjs-default-skin vjs-16-9" id="videoLead"></video>
                              {!clickedPlay ? <div className="play-big-btn" onClick={() => {playVideo()}}></div> : null}
                              {clickedPlay && paused ? <div className="play-big-btn" onClick={(e) => {doPlayVideo(e)}}></div> : null}
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-4 pl-3 pr-3 lead-page-right ovchat" id="chatBox">
                            <div className="chat-info w-100 mt-3 px-3 pt-3">
                              {jobTitle && name ?
                              <div className="row">
                                <div className="col-12 pl-0 ml-0">
                                <img height="80" src={actorImage()} alt="" />
                                  <span className="name"> {name} <br /> <span className="job-title text-secondary">{jobTitle}</span></span>
                                </div>
                              </div>
                               : null }
                              <div className="row text-message mt-3">
                                <div className="col-12">
                                  {welcomeMessage}
                                  {enableButton ? <a href={buttonUrl} onClick={(e) => {buttonAction(e)}} target="_blank" className="cta-button mb-3">{buttonText}</a> : ''}
                                </div>
                              </div>
                            </div>
                            { enableChat && messages && messages.length
                              ?
                              messages.map((item, index) =>
                              <div key={`messages-landing-page-${index}`} className="chat-box d-flex flex-column p-3">
                                  <div className="chat-new">
                                    <div className="chat-autor">
                                      <p className="name mb-0">{clientName ? clientName : ''}</p>
                                      <p className="mail-chat">{item.email}</p>
                                      <p className="message pl-3">{item.message}</p>
                                    </div>
                                  </div>
                              </div>
                              )
                              :
                              null
                            }
                            { enableChat ?
                            <form method="POST" encType="multipart/form-data" name="EmailForm">
                              <div className="send-msg-out">
                                <div className="text-holder">
                                  <textarea className="form-control chat-placeholder mb-3" name="message" rows="2" value={message} onChange={(e) => {setMessage(e.target.value)}}></textarea>
                                  <button type="submit" className="send-msg-arr" onClick={(e) => {sendMessage(e)}}>
                                    <i className="fas fa-location-arrow"></i>
                                  </button>
                                </div>
                              </div>
                            </form>
                            : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {
          raBranding
          ?
        <div className="reachout-branding">
          <a href={'https://reachout.ai?utm_source=badge&utm_medium=landingpage&utm_campaign=' + campaignId} target="_blank">
            <img src="/reachout-tag.png" width="164" height="37" alt="" />
          </a>
        </div>
        :
        null
        }
    </div>
  );
}

export default Home;