import React, { useEffect } from 'react';

const Video = () => {

  useEffect(() => {
    document.body.className += ' video-player-body';


  }, []);

  return ( <div className="video-reachout-player-wrapper">
              <iframe className="reachout-player" src={ process.env.REACT_APP_URL + 'player'} frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen="0" ></iframe>
          </div>
    );
}

export default Video;