import axios from 'axios';

axios.defaults.withCredentials = true;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

const getLangingPageByVideoPersonalizationIdUrl = 'pages/landing-page-by-video-personalization-id';
const getVideoFromVideoPersonalizationIdAndUserEmail = 'videos/video-by-video-personalization-and-email';
const getVideoFromvideoUUID = 'videos/by-id';
const leadUrl = 'outgoing-webhook/page-lead';
const analiticsVideoView = 'analitycs/video-view';
const analiticsCtaAction = 'analitycs/cta-action';
const analiticsPageView = 'analitycs/page-view';
const analiticsPageMessage = 'analitycs/page-message';
const pageMessages = 'page-messages';
const shareAnaliticsGet = 'analitycs-shared';

var headers = () => {

    let headers = {
            Accept: 'application/json',
        }

    return headers;
}

export const getLandingPageByVideoPersonalizationId = async (videoPersonalizationId, cliked) => {

  let sendHeaders = headers();

  const result = await axios.get(process.env.REACT_APP_API_URL + getLangingPageByVideoPersonalizationIdUrl + '/' + videoPersonalizationId, {params: {cliked: cliked}}, {headers: sendHeaders});

  return result.data;
};

export const getVideoByVideoPersonalizatioNIdAndEmail = async (videoPersonalizationId, email) => {

  let sendHeaders = headers();

  const result = await axios.get(process.env.REACT_APP_API_URL + getVideoFromVideoPersonalizationIdAndUserEmail + '?video_personalization_id=' + videoPersonalizationId + '&email=' + email, {headers: sendHeaders});

  return result.data;
};

export const getVideoByUniqueId = async (videoUUID, email, vid) => {

  let sendHeaders = headers();

  let result;

  if (videoUUID) {
    result = await axios.get(process.env.REACT_APP_API_URL + getVideoFromvideoUUID + '/' + videoUUID, {headers: sendHeaders});
  } else if (email) {
    result = await axios.get(process.env.REACT_APP_API_URL + getVideoFromvideoUUID , {params: {email: email, vid: vid}, headers: sendHeaders});
  }

  return result.data;
};

export const getVideoByVideoId = async (videoId) => {

  let sendHeaders = headers();

  let result = await axios.get(process.env.REACT_APP_API_URL + 'videos/by-video-id/' + videoId , {headers: sendHeaders});

  return result.data;
};

export const convertedLead = async (campaignId, videoUUID) => {

  let sendHeaders = headers();

  const result = await axios.post(process.env.REACT_APP_API_URL + leadUrl + '/' + campaignId, {uuid: videoUUID}, {headers: sendHeaders});

  return result.data;
};

//analitycs
export const videoView = async (campaignId, videoId, email, uuid) => {

  let sendHeaders = headers();

  const result = await axios.post(process.env.REACT_APP_API_URL + analiticsVideoView, {
    campaign_id: campaignId,
    video_id: videoId,
    email: email,
    uuid: uuid,
  }, {headers: sendHeaders});

  return result.data;
};

export const ctaAction = async (campaignId, videoId, email, uuid) => {

  let sendHeaders = headers();

  const result = await axios.post(process.env.REACT_APP_API_URL + analiticsCtaAction, {
    campaign_id: campaignId,
    video_id: videoId,
    email: email,
    uuid: uuid,
  }, {headers: sendHeaders});

  return result.data;
};

export const pageView = async (campaignId, videoId, email, name, uuid) => {

  let sendHeaders = headers();

  const result = await axios.post(process.env.REACT_APP_API_URL + analiticsPageView, {
    campaign_id: campaignId,
    video_id: videoId,
    email: email,
    name: name,
    uuid: uuid,
  }, {headers: sendHeaders});

  return result.data;
};

export const pageMessage = async (campaignId, videoId, email, message, uuid) => {

  let sendHeaders = headers();

  const result = await axios.post(process.env.REACT_APP_API_URL + analiticsPageMessage, {
    campaign_id: campaignId,
    video_id: videoId,
    email: email,
    message: message,
    uuid: uuid,
  }, {headers: sendHeaders});

  return result.data;
};

//messages
export const getMessages = async (videoId) => {

  let sendHeaders = headers();

  const result = await axios.get(process.env.REACT_APP_API_URL + pageMessages + '/' + videoId, {headers: sendHeaders});

  return result.data;
};

export const getSharedAnalytics = async (campaignId, page, date, search, tag, toDate, shareKey, stepInSequenceSelected, filters) => {

  let sendHeaders = headers();

  const result = await axios.get(process.env.REACT_APP_API_URL + shareAnaliticsGet + '/' + campaignId + '/' + shareKey, {
    params: {
      page: page,
      date: date,
      date_to: toDate,
      search: search,
      tag: tag,
      step_in_sequence_selected: stepInSequenceSelected,
      filters: filters
    }
  }, {headers: sendHeaders});

return result.data;

};

//unsubscribe user from video
export const unsubscribeFromVideo = async (videoPersonalizationId, uuid) => {

  let sendHeaders = headers();

  const result = await axios.get(process.env.REACT_APP_API_URL + 'videos/unsubscribe/' + videoPersonalizationId + '/' + uuid, {headers: sendHeaders});

  return result.data;
};
