import React, { Component } from "react";
import "./App.scss";
import Home from './Home'
import VideoFull  from "./components/VideoFull";
import VideoTest  from "./components/VideoTest";
import Video from "./components/Video";
import Unsubscribe from "./components/Unsubscribe";
import AnalyticsShared from "./components/AnalyticsShared";
import {Route, Switch} from 'react-router-dom'

class App extends Component {
  render() {
    return <>

      <Switch>
        <Route path="/player/:videoPersonalizationId/:videoUUID?" exact component={VideoFull} />
        <Route path="/video" exact component={Video} />
        <Route path="/unsubscribe/:videoPersonalizationId/:videoUUID" exact component={Unsubscribe} />
        <Route path="/analytics-shared/:campaign_id/:share_key" exact component={AnalyticsShared} />
        <Route path="/test" exact component={VideoTest} />
        <Route path="/:landingPageId/:videoUUID?" exact component={Home} />
      </Switch>

    </>
  }
}

export default App;
